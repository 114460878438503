export const personData = {
  name: "Mariusz Matusiewicz",
  email: "mariusz.myprojects@gmail.com",
  description:
    "I'm passionate about frontend development, especially using React. My goal is to develop my skills and turn this passion into additional work.",
  invitation:
    "I'm always open to new projects whenever I have time. If you're planning a website, dashboard, and need help bringing your ideas to life, feel free to contact me",
  githubData: {
    username: "mariuszmmm",
    topic: "public-repo",
    favoriteList: [
      "movies-browser",
      "personal-homepage",
      "to-do-list-react",
      "to-do-list",
      "currency-converter-react",
      "currency-converter",
      "calculator-react",
      "photo-catalog",
      "web-development",
      "bieszczady",
    ],
  },
  socialLinks: {
    github: "https://github.com/mariuszmmm",
    linkedin: "https://www.linkedin.com/in/mariuszmatusiewicz",
    facebook: "https://www.facebook.com/profile.php?id=100087408762484",
    instagram: null,
  },
  skills: [
    "Semantic and accessible HTML",
    "Responsive Web Design",
    "Teamwork",
    "Markdown",
    "Immutability",
    "CSS BEM convention",
    "CSS Flexbox",
    "CSS Grid",
    "React Router",
    "Redux-Saga",
    "Redux (Toolkit)",
    "React Hooks",
    "Error handling",
    "Working with API (fetch, axios)",
    "JavaScript ES6+",
    "Promises, Async/Await",
    "GitHub Pull Requests & Review",
    "Trello",
    "Scrum",
    "NPM",
    "React",
    "Styled Components",
    "Git",
  ],
  toLearn: [
    "React Context",
    "React Native",
    "LUA",
    "Cypress",
    "TypeScript",
    "Node.js",
    "Unit testing",
    "JS classes",
    "MongoDB",
  ],
};
